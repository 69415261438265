// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

(function ($) {
    $( document ).ready(function() { 

      jQuery("#filtroSolicitacoes select").on("change", function(){
    
        jQuery("#eventWrapper > div").show();
        
        var filter = "";
        
        jQuery('#filtroSolicitacoes').find("[data-form]").each(function(){
            if( jQuery(this).val() != "" ) filter += ("[" + jQuery(this).attr("data-form") + "='" + jQuery(this).val() + "']");
        });    

        if(filter.length > 0) jQuery("#eventWrapper > div").not(filter).hide();
        
        $('.remove-filter-wrapper').show();
        
        var elmntLengthDefault  = jQuery('#eventWrapper > div').length;
        var elmntLengthHidden   = jQuery('#eventWrapper > div[style*="display: none"]').length;

        if (elmntLengthDefault == elmntLengthHidden) {
          jQuery('#job-message').remove();
          jQuery('<div id="job-message"><p>Aktuell haben wir leider keine Events an diesem Standort.</p></div>').appendTo("#eventWrapper");
        }

        return false;

      });


      jQuery(".delete-filter").on("click", function(){
        jQuery(this).remove();
        jQuery("#eventWrapper > div").show();
        jQuery('.portfolio-controllers select').prop('selectedIndex',0);
        jQuery('.portfolio-controllers-fach select').prop('selectedIndex',0);
        jQuery('.portfolio-controllers-ferien select').prop('selectedIndex',0);
      });

      $('.remove-filter-wrapper').hide();

        // lets render the sidenav for the single locations

      setTimeout(function(){
        $('.location-wrapper div label').click(function() {
            var standort = $(this).text();
            $('#wsf-1-label-32').css('text-transform' , 'capitalize');
            $('#wsf-1-label-32').text(standort);
            //$("#txtAge").toggle(this.checked);
        });
      }, 100);
        if ($('body.page-template-page-single-standort') && screen.width > 767) {

            // append the sidenav wrapper to body
            $('body main').append('<div id="sidenav" class="sidenav-wrapper"><div class="sidenav-content"></div></div>');


            $( "body.page-template-page-single-standort main h2" ).each(function( index ) {

              var idName = $( this ).text();
              // lets make the texts lowercase, replace umlaute and make them look more like an ID
              var id = $( this ).text();
              id = id.replace(/ /g, "-");
              id = id.replace(/\u00e4/g, "ae");
              id = id.replace(/\u00c4/g, "Ae");
              id = id.replace(/\u00fc/g, "ue");
              id = id.replace(/\u00dc/g, "Ue");
              id = id.replace(/\u00f6/g, "oe");
              id = id.replace(/\u00d6/g, "Oe");
              id = id.replace(/\u00df/g, "ss");
              id = id.indexOf('-') == 0 ? id.substring(1) : id;
              id = id.toLowerCase();

              // add the new ID to its elmnt
              $( this ).attr('id' , id); 
              // lets push the link elmnt into the sidenav
              $('#sidenav .sidenav-content').append('<a href="#'+id+'" id="'+id+'"><span>'+idName+'</span></a>');
            });

            $('#sidenav .sidenav-content a').on('click', function(e) {
              id = $(this).attr('href');
              $('body main section h2.is-in-view').removeClass('is-in-view');
              $('#sidenav .sidenav-content a.is-in-view').removeClass('is-in-view');
              $(this).addClass('is-in-view');
            });
            
            $.fn.isInViewport = function () {
                let elementTop = $(this).offset().top;
                let elementBottom = elementTop + $(this).outerHeight() - 150;
                let viewportTop = $(window).scrollTop();
                let viewportBottom = viewportTop + $(window).height() - 150;
                return elementBottom > viewportTop && elementTop < viewportBottom;
            };
            
            $(window).on("scroll load resize", function () {
                $( "body.page-template-page-single-standort main h2" ).each(function() {
                    if( $(this).isInViewport() ) {
                        $(this).addClass('is-in-view');
                        var idName = $( this ).attr('id');
                        $('#sidenav .sidenav-content').find('#'+idName).addClass('is-in-view');
                    }else{
                      var idName = $( this ).attr('id');
                      $('#sidenav .sidenav-content').find('#'+idName).removeClass('is-in-view');
                    }
                });
            });

        }  

        if ($('body.page-template-page-home')) {
          const locations = []
          $('body.page-template-page-home .capito-standorte .row .standort-item').each(function( index ) {
              var id = $(this).attr('id');
              locations.push(id);
          });
          var locationsorted = locations.sort();
          const locationhtml = []
          $(locationsorted).each(function(index) {
              var id = locationsorted[index];
              var html = $('body.page-template-page-home .capito-standorte .standort-content-wrapper').find('#'+id).html();
              var html = '<div class="standort-item col-6 py-1" id="'+id+'">'+html+'</div>';
              locationhtml.push(html);
          });
          $('body.page-template-page-home .capito-standorte .standort-content-wrapper .standort-item').remove();
          $('body.page-template-page-home .capito-standorte .standort-content-wrapper').append(locationhtml);
        }

        $('#navbarMain .depth_0 .menu-item-has-children.dropdown > .dropdown-item').append('<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve"><style type="text/css">.st0{fill:#575756;}</style><g><g><path class="st0" d="M223.2,800c-11.8,0-23.7-4.5-32.8-13.6c-18.1-18.1-18.1-47.4,0-65.5L511.3,400L190.4,79.1 c-18.1-18.1-18.1-47.4,0-65.5c18.1-18.1,47.4-18.1,65.5,0l353.7,353.7c18.1,18.1,18.1,47.4,0,65.5L255.9,786.4 C246.9,795.5,235,800,223.2,800z"/></g></g></svg>');
        $('#navbarMain .mobile-nav-custom .menu-item-has-children > a').append('<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve"><style type="text/css">.st0{fill:#575756;}</style><g><g><path class="st0" d="M223.2,800c-11.8,0-23.7-4.5-32.8-13.6c-18.1-18.1-18.1-47.4,0-65.5L511.3,400L190.4,79.1 c-18.1-18.1-18.1-47.4,0-65.5c18.1-18.1,47.4-18.1,65.5,0l353.7,353.7c18.1,18.1,18.1,47.4,0,65.5L255.9,786.4 C246.9,795.5,235,800,223.2,800z"/></g></g></svg>');

        if (screen.width > 1366){
          $('.desktop-nav .depth_0 .dropdown-sub').on('mouseenter', function(e) {
            $(this).css('z-index' , '10');
            $(this).find('.depth_1').addClass("show");
          });
          $('.desktop-nav .depth_0 .dropdown-sub').on('mouseleave', function(e) {
              $(this).css('z-index' , '0');
              $(this).find('.depth_1.show').removeClass("show");
          });
        }else{
          $('.desktop-nav .depth_0 .dropdown-sub').on('click', function(e) {
            $('.desktop-nav .depth_0 .dropdown-sub').css('z-index' , '0');
            $(this).css('z-index' , '10');
            $('li#menu-item-232').addClass('show');
              setTimeout(function(){
                $('li#menu-item-232').parent().addClass('show');
              }, 1);
              //$('li#menu-item-232').find('.depth_1').addClass("show");
          });
        }
        //$('.mobile-nav-custom .dropdown-sub').on('click mouseover', function(e) {
        //    $(this).find('.sub-menu').toggleClass('d-none'); 
        //})

        //This is to stop the Bootstrap menu from closing when a link is clicked.
        $(document).on('click mouseover', '.dropdown', function (e) {
            e.stopPropagation();
        }); 

        if (screen.width > 1024) {
          $(document).on('click mouseover', '.sub-menu', function (e) {
            $(this).stopPropagation();
            $(this).preventDefault();
          }); 
        }

        var headerHeight = $('#site-navbar').outerHeight();
        $('#navbarSearch').css('top' , headerHeight);

        var elmntHeight = [];
        $('.capito-locations .row .content-wrapper .name-wrapper').each(function( index ) {
          var height = $(this).outerHeight();
          elmntHeight.push(height);
        }); 
        var BiggestHeight = Math.max.apply(Math,elmntHeight);
        $('.capito-locations .row .content-wrapper .name-wrapper').css('height' , BiggestHeight);
        console.log(BiggestHeight);

        var scrollTopBtn = jQuery("button.scroll-top");
        $('#carouselExampleControls .carousel-inner .carousel-item').eq(0).addClass('active');

        var $dropdown = $(".desktop-nav .dropdown");
        var $dropdownToggle = $(".desktop-nav .dropdown-toggle");
        var $dropdownMenu = $(".desktop-nav .dropdown-menu.depth_0");
        var showClass = "show"; 
         
        $(window).on("load resize", function() {
          if (screen.width > 1366) {
            $dropdown.hover(
              function() {
                var $this = $(this);
                $this.addClass(showClass);
                $this.find($dropdownToggle).attr("aria-expanded", "true");
                $this.find($dropdownMenu).addClass(showClass);
              },
              function() {
                var $this = $(this);
                $this.removeClass(showClass);
                $this.find($dropdownToggle).attr("aria-expanded", "false");
                $this.find($dropdownMenu).removeClass(showClass);
              }
            );
          } else {
            $dropdown.off("mouseenter mouseleave");
          }
        });

        if ($('.mobile-nav-custom')) {
          $('.mobile-nav-custom .menu-item-has-children .sub-menu').addClass('d-none');
          $('.mobile-nav-custom > .menu-item-has-children').addClass('subnav-opener');
          if ($('.mobile-nav-custom .menu-item-has-children .sub-menu .sub-menu')) {
            $('.mobile-nav-custom .menu-item-has-children .sub-menu .sub-menu').each(function( index ) {
              $(this).addClass('level-1');
            });
            $('.mobile-nav-custom ul.level-1').parent().addClass('level-1-trigger');
          }


          $('.mobile-nav-custom .menu-item-has-children.subnav-opener > a').on( "click", function(e) {
            e.preventDefault();
            $(this).parent().addClass('show');
            if ($('.mobile-nav-custom > .menu-item-has-children.subnav-opener.show').length >= 1) {
              $('.mobile-nav-custom > .menu-item-has-children.subnav-opener').not(this).parent().removeClass('show');
              $('.mobile-nav-custom > .menu-item-has-children.subnav-opener').not(this).parent().find('.sub-menu').removeClass('d-block').addClass('d-none');
            }
            if ($(this).parent().find('.sub-menu').not('.level-1').hasClass('d-none')) {
              $(this).parent().find('.sub-menu').not('.level-1').removeClass('d-none');
              $(this).parent().find('.sub-menu').not('.level-1').addClass('d-block');
            }
          });

          $('.mobile-nav-custom .menu-item-has-children .level-1-trigger').on( "click", function(e) {
            //e.preventDefault();
            e.stopPropagation();
            $(this).addClass('show');
            if ($(this).find('.sub-menu.level-1').hasClass('d-none')) {
              $(this).find('.sub-menu.level-1').removeClass('d-none');
              $(this).find('.sub-menu.level-1').addClass('d-block');
            }
            if ($('.mobile-nav-custom .menu-item-has-children .level-1-trigger.show').not(this)) {
              $('.mobile-nav-custom .menu-item-has-children .level-1-trigger.show').not(this).find('.sub-menu.level-1').addClass('d-none'); 
              $('.mobile-nav-custom .menu-item-has-children .level-1-trigger.show').not(this).removeClass('show'); 
              $('.mobile-nav-custom .menu-item-has-children .level-1-trigger.show').not(this).find('.sub-menu.level-1').removeClass('d-block'); 
            }
          });
        }



        $(".scroll-top").click(function() {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          return false;
        });

        $(".navbar-toggler").click(function() {
          $(this).toggleClass('active');
        });

        //var myCarousel = document.getElementById('carouselExampleControls')
        //$(myCarousel).find('.active').next().addClass('next');
        //$(myCarousel).find('.carousel-item').last().addClass('prev');
        //var lastslide = $(this).find('.carousel-item').last();
        //$(lastslide).insertBefore('#carouselExampleControls .active').addClass('cloned-last-slide');
        
        //myCarousel.addEventListener('slid.bs.carousel', function () {
        //  $(this).find('.prev').removeClass('prev');
        //  $(this).find('.active').removeClass('prev next');
//
//        //  if ($(this).find('.active').next().length) {
//        //    console.log('next found');
//        //    $(this).find('.active').next().addClass('next');
//        //    //$('#carouselExampleControls .cloned-last-slide').remove();
//        //  }else{
//        //    console.log('next is first');
//        //    //var lastslide = $(this).find('.carousel-item').first();
//        //    //$(lastslide).insertAfter('#carouselExampleControls .active').addClass('cloned-first-slide next');
//        //    //$(this).find('.cloned-last-slide').first().addClass('next');
//        //    $(this).find('.carousel-item').first().addClass('next');
//        //  }
//
//
//
//        //  if ($(this).find('.active').prev().length) {
//        //    console.log('prev found');
//        //    $(myCarousel).find('.carousel-item').last().removeClass('prev');
//        //    $(this).find('.active').prev().addClass('prev');
//        //  }else{
//        //    console.log('prev ist last');
//        //    var lastslide = $(this).find('.carousel-item').last();
//        //    $(myCarousel).find('.active').prependTo(lastslide);
//        //    //var lastslide = $(this).find('.carousel-item').last();
//        //    //$(lastslide).insertBefore('#carouselExampleControls .active').addClass('cloned-last-slide');
//        //    //$(this).find('.cloned-last-slide').last().addClass('prev');
//        //  }
        //})

        var multipleCardCarousel = document.querySelector("#imggallery");
        $('#imggallery .carousel-item').first().addClass('active');
        if (window.matchMedia("(min-width: 767px)").matches && multipleCardCarousel) {
          var slidercontent = $('#imggallery .carousel-inner').html();
          $('#imggallery .carousel-inner').append( slidercontent );
          $('#imggallery .carousel-inner').append( slidercontent );
          var carousel = new bootstrap.Carousel(multipleCardCarousel, {
            wrap: true
          });
          var carouselWidth = $(".carousel-inner")[0].scrollWidth;
          var cardWidth = $(".carousel-item").width();
          var scrollPosition = 0;
          $("#imggallery .carousel-control-next").on("click", function () {
            if (scrollPosition < carouselWidth - cardWidth * 4) {
              scrollPosition += cardWidth;
              $("#imggallery .carousel-inner").animate(
                { scrollLeft: scrollPosition },
                600
              );
            }
          });
          $("#imggallery .carousel-control-prev").on("click", function () {
            if (scrollPosition > 0) {
              scrollPosition -= cardWidth;
              $("#imggallery .carousel-inner").animate(
                { scrollLeft: scrollPosition },
                600
              );
            }
          });
        } else {
          $(multipleCardCarousel).addClass("slide");
        }


    });
})(jQuery);